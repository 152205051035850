import React from 'react';
import Sectitle from "../../Title/Sectitle";
import Tabitem from "./CloudServiceTabItem";

const CloudServiceTab =()=>{
    return(
        <section className="software_service_area sec_pad">
            <div className="container">
                <Sectitle Title="Our Focus Areas" 
                 sClass="sec_title text-center mb_70"/>
                <div className="row">
                    
                    <div className="col-lg-12 col-md-12">
                        <div className="tab-content software_service_tab_content">
                            <div className="tab-pane fade show active" id="de" role="tabpanel" aria-labelledby="de-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-credit-card" text="Ecommerce Ventures" description=<div><ul><li>Ecommerce is the future, and we’re at the forefront.</li>
                                    <li> We seek out disruptive startups and established players in the online retail space.</li><li>From last-mile delivery to supply chain optimization, we understand the critical components of successful ecommerce operations.</li></ul></div>/>

                                    <Tabitem colClass="offset-lg-2" ticon="ti-wallet" text="Financial Services" description=<div>
                                    <ul>
                                    <li>We provide expertise and capital to financial services firms to drive growth and innovation.</li>
                                    <li>Our expertise extends to market, product, and talent identification, business development, risk mitigation, liquidity management, and corporate and investment banking advice.</li>
                                    </ul>
                                    </div>/>
                                    <Tabitem ticon="ti-world" text="International Trade" description=<div>
                                    <ul>
                                    <li>Our team navigates the complexities of international markets.</li>
                                    <li>We identify, facilitate, and directly engage in trade relationships.</li>
                                    <li>Whether it’s sourcing products or expanding market reach, we partner to find tailored solutions.</li>
                                    </ul>
                                    </div>/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-home" text="Real Estate Investment" description=<div>
                                    <ul>
                                    <li>We invest in a wide range of real estate projects, from residential developments to commercial properties.</li>
                                    <li>Our goal is to maximize returns while minimizing risk.</li>
                                    
                                    </ul>
                                    </div>/>
                                </div>
                            </div>
                           
                           
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CloudServiceTab;