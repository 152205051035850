import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/


import ScrollToTopRoute from "./ScrollToTopRoute";

import HomeCloud from "./Pages/Home-cloud";

import ServiceDetails from "./Pages/ServiceDetails";
import NotFound from "./Pages/404";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          
         
          <ScrollToTopRoute path="/" component={HomeCloud} />
         
          
          
          <ScrollToTopRoute path="/ServiceDetails" component={ServiceDetails} />
          
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
